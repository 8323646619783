import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { navigate } from "@reach/router";
class ErrorTemplate extends Component {
  // Crear query en template page per integraciones (recuperar tots els posts de la categoria i passar-ho per propietats)
  // Una vegada s'hagi carregat el VDOM afegir els posts de cada categoria com a attribut i desde el jquery o aquí mateix crear la funcionalitat de tags
  render() {
    if (this.props.data.wpPage === undefined) {
      navigate("/es/");
      return null;
    }
    const langPage = this.props.pageContext.idioma;
    const seo = this.props.data.wpPage.seo;
    const developers = this.props.data.wpPage.developers;
    const slugPage = "/" + langPage + "/404";
    const openGraphslugPage = seo.opengraphUrl;
    const alternates = this.props.data.wpPage.translated;
    return (
      <div>
        {this.props.pageContext.idioma !== undefined
          ? (
            <Layout
              currenPage="404"
              langPage={this.props.pageContext.idioma}
              idPage={this.props.data.wpPage.id}
              id_pagina={developers !== null ? developers.idPagina : ""}
              clase_pagina={developers !== null ? developers.clasePagina : ""}
              alternates={alternates}
            >
              <SEO
                title={seo.title}
                description={seo.metaDesc}
                canonical={slugPage}
                robotsNofollow={seo.metaRobotsNofollow}
                robotsNoindex={seo.metaRobotsNoindex}
                openGraphSite={seo.opengraphSiteName}
                openGraphType={seo.opengraphType}
                openGraphUrl={openGraphslugPage}
                openGraphImg={
                  seo.opengraphImage !== null
                    ? seo.opengraphImage.sourceUrl
                    : "/images/logo-billage-default-social-share.jpg"
                }
                openGraphImgWidth={
                  seo.opengraphImage !== null
                    ? seo.opengraphImage.mediaDetails.width
                    : 300
                }
                openGraphImgHeight={
                  seo.opengraphImage !== null
                    ? seo.opengraphImage.mediaDetails.height
                    : 300
                }
                dctermsCreated={this.props.data.wpPage.date}
                dctermsModified={this.props.data.wpPage.modified}
                lang={langPage}
                twitterImg={
                  seo.twitterImage !== null
                    ? seo.twitterImage.sourceUrl
                    : "/images/logo-billage-default-social-share.jpg"
                }
                twitterImgHeight={
                  seo.twitterImage !== null
                    ? seo.twitterImage.mediaDetails.height
                    : 300
                }
                twitterImgWidth={
                  seo.twitterImage !== null
                    ? seo.twitterImage.mediaDetails.width
                    : 300
                }
                alternates={alternates}
              />
              <div className="row page-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.data.wpPage.page_content.contentRaw
                  }}
                />
              </div>
            </Layout>
          )
          : null}{" "}
      </div>
    );
  }
}

export default ErrorTemplate;

export const errorPageQuery = graphql`
  query errorPageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      id
      page_content {
        contentRaw
      }
      developers {
        idPagina
        clasePagina
      }
      translated {
        slug
        locale{
          locale
        }
      }
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
    }
  }
`;
